import styles from './Approve.module.css';
import { useState, useEffect } from 'react';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Select from 'react-select';
import { useRecoilState } from 'recoil';
import {
  emailToApproveState,
  clickedManuscriptAtom,
  pendingProcessingState,
  userInfoState,
  manualLoginState
} from '../../../state';

import x from '../../../icons/vuesax/outline/addx.svg';

import useApiUtils from '../../../useApiUtils';
import AvatarCostom from '../../../UI/AvatarCostom';
import { renderCenteredAlert } from '../../CenteredAlert';
const Approve = (props) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [selectedOption, setSelectedOption] = useState(null);
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [manualLogin, setManualLogin] = useRecoilState(manualLoginState);

  const [options, setOptions] = useState([]);
  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  const [next_user_option, setNext_user_option] = useState([]);

  const [isApproved, setIsApproved] = useState(false);
  const [undo, setUndo] = useState(false);

  const [emailToApprove, setEmailToApprove] =
    useRecoilState(emailToApproveState);
  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    // Add other functions from the hook if needed
  } = useApiUtils();

  //create mock data
  const img = 'https://www.w3schools.com/howto/img_avatar.png';
  const title = 'The Title of the Manuscript';
  const outher = 'Outher Name';
  const emailText = 'blablbjckjdfhxgbc';

  //setNext_user_option from  userInfo.next_users list
  useEffect(() => {
    if (userInfo && userInfo?.next_users.length > 0) {
      let next_user_option_temp = [];
      userInfo.next_users.forEach((user) => {
        next_user_option_temp.push({
          value: user.user_id,
          label: user.name,
          avatarSrc: 'https://www.w3schools.com/howto/img_avatar.png',
        });
      });
      setNext_user_option(next_user_option_temp);
    }
  }, [userInfo]);

  const handleOut = () => {
    props.handleApprove(false);
  };

  const handelbutton = () => {
    props.return(false);
  };

  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    //if selectedOption is not null then alert that you can select only one of the option and clear both
    if (selectedOption != null) {
      renderCenteredAlert(
        'You can select only one of the options, via email or select user'
      );
      setEmail('');
      setSelectedOption(null);
      return;
    }
    setEmail(event.target.value);
  };

  useEffect(() => {
    setOptions(next_user_option);
  }, [next_user_option]);

  const handleOptionChange = (selected) => {
    //if email is not empty then alert that you can select only one of the option and clear both
    if (email != '') {
      renderCenteredAlert(
        'You can select only one of the options, via email or select user'
      );
      setEmail('');
      setSelectedOption(null);
      return;
    }

    setSelectedOption(selected);
  };

  const CustomSingleValue = ({ innerProps, label, data }) => (
    <div {...innerProps} className={styles.customOption}>
      <Stack direction="row" spacing={2} alignItems="center">
        {/* <Avatar alt={data.label} src={data.avatarSrc} /> */}
        <AvatarCostom name={data.label} />
        <span className={styles.optionLabel}>{data.label}</span>
      </Stack>
    </div>
  );
  const CustomOptionComponent = ({ innerProps, label, data }) => (
    <div {...innerProps} className={styles.customOptionn}>
      <Stack direction="row" spacing={2} alignItems="center">
        {/* <Avatar alt={data.label} src={data.avatarSrc} /> */}
        <AvatarCostom name={data.label} />
        <span className={styles.optionLabel}>{data.label}</span>
      </Stack>
    </div>
  );

  const NoInput = () => null;

  const customStyles = {
    container: (provided) => ({
      ...provided,
      padding: 0,
      marginRight: 0,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '16px', // Add border-radius attribute
      border: '1px solid var(--sass-gray-100, #C4CAD4)', // Add border attribute
      background: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
      gap: 0,
      backgroundColor: '#f6f7fa',
      width: '100%',
    }),
    option: (
      styles,
      { data, isDisabled, isFocused, isSelected, isHovered }
    ) => ({
      ...styles,
    }),
  };

  const handleApproveBefore = () => {
    if (userInfo.role === 'lvl1') {
      if (selectedOption == null && email == '') {
        renderCenteredAlert('Please select a user to send the manuscript to');
        props.setUserApproved(false);
        return;
      }
    }
    let emailToSendMethod;
    let emailToSend;
    if (selectedOption != null) {
      emailToSend = selectedOption.value;
      emailToSendMethod = 'user';
    } else if (email != '') {
      emailToSend = email;
      emailToSendMethod = 'email';
    } else {
      emailToSend = email;
      emailToSendMethod = 'email';
    }
    //set emailToApprove with emailToSend , emailToSendMethod
    // setEmailToApprove({ email: emailToSend, method: emailToSendMethod });
    // props.setUserApproved(true);
    const nextUser = selectedOption;
    sendApprove(clickedManuscript.book_id, {
      email: emailToSend,
      method: emailToSendMethod,
    });
    props.handleApprove(false);
    props.return(false);
  };

  const handleUndoApprove = () => {
    setUndo(true);
    setIsApproved(false);
    props.handleApprove(false);
  };

  return (
    <div className={manualLogin ?  styles.manualbackdrop : styles.backdrop}>
      {/* <div className={styles.container}> */}
      <div className={styles.TopSection}>
        <div className={styles.buttonOut} onClick={handleOut}>
          <img className={styles.xbutton} src={x} />
          {/* <div className={styles.xbutton}>X</div> */}
        </div>

        <div className={styles.MiddleSection}>
          <div className={styles.ImageContainer}>
            <img
              className={styles.Image}
              src={clickedManuscript.image}
              alt=""
            />
          </div>
          <div className={styles.title}>
            {clickedManuscript.manuscript_name}
          </div>
          <div className={styles.outherName}>
            By{' '}
            <div className={styles.outher}>{clickedManuscript.author_name}</div>
          </div>
        </div>
        <div className={styles.subMiddleSection}>
          <div className={styles.ConfirmationTitle}>
            Are You Sure You Want to Approve This Manuscript?
          </div>
          {userInfo.role === 'lvl1' && (
            <div className={styles.ConfirmationSubtitle}>
              If you approve this manuscript, a notification will be sent to the
              following user:
            </div>
          )}
          {userInfo.role === 'lvl2' && (
            <div className={styles.ConfirmationSubtitle1}>
              If you approve this manuscript, you will complete the review
              process.
            </div>
          )}
          {userInfo.role === 'lvl1' && (
            <div className={styles.emailpart}>
              <div className={styles.dropdownmail}>
                <label className={styles.dropdownLabel}>Select User</label>

                <Select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  options={options}
                  styles={customStyles}
                  components={{
                    Option: CustomOptionComponent,
                    SingleValue: CustomSingleValue,
                    Input: NoInput, // Override default input rendering
                  }}
                />
              </div>
              <div className={styles.orLine}>
                <div className={styles.Line} />
                <div className={styles.orLineText}>OR</div>
                <div className={styles.Line} />
              </div>
              <div className={styles.enteremailto}>
                <label className={styles.emailText}>Enter Email</label>
                <div className={styles.emailInputContainer}>
                  <input
                    type="text"
                    className={styles.emailInput}
                    placeholder="Enter email    @"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isApproved && (
        <div className={styles.ButtonSection}>
          <div className={styles.YesButtonContainer} onClick={handleOut}>
            <div className={styles.yesButton}>No, Don’t Approve</div>
          </div>
          <div
            className={styles.NoButtonContainer}
            onClick={handleApproveBefore}
          >
            <div className={styles.noButton}>Yes, Approve</div>
          </div>
        </div>
      )}
      {isApproved && (
        <div className={styles.ButtonSection}>
          <div
            className={styles.YesButtonContainer}
            onClick={handleUndoApprove}
          >
            <div className={styles.yesButton}>Undo</div>
          </div>
          <div className={styles.NoButtonContainer} onClick={handleOut}>
            <div className={styles.noButton}>Approved</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Approve;
