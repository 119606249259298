import styles from './Header.module.css';

import ring from '../../icons/ring.svg';
import deff from '../../icons/deff.svg';
import expenduser from '../../icons/expenduser.svg';
import storyWiswLogo from '../../icons/storywise.svg';
import book from '../../icons/book.svg';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

import { useRecoilState } from 'recoil';
import {
  userRejected_ApprovedNoteState,
  initialHighlightState,
  most_similar_bookAtom,
  fileState,
  fileInfoState,
  idTokeneState,
  accessTokenState,
  notes_highlightsState,
  companyInfoState,
  numberOfUploadedFilesState,
  userInfoState,
  headerColumnsTranslationState,
  loadingmanuscriptState,
  pendingProcessingState,
  loadingStateCompare,
  currentManuscriptAtom,
  afterGetBookChapter,
  clickedManuscriptAtom,
  genresState,
  keywordsState,
  genresDataState,
  scoreDataAtom,
  chapterNamesAtom,
  selectedChapterAtom,
  contentAtom,
  notesAtom,
  manuscriptInfoListAtom,
  startPollingState,
  books_id_dwonloadState,
  manualLoginState,
} from '../../state';
import useApiUtils from '../../useApiUtils';
import { useEffect, useState, useRef } from 'react';
import logout from '../../icons/Leftdwonicon.svg';

import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

const REACT_APP_BUILD_ENV =
  process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
const REACT_APP_API_ID =
  process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'USER_POOL_ID-build_env',
  ClientId:
    process.env.REACT_APP_CLIENT_ID_USER_POOL ||
    'CLIENT_ID_USER_POOL-build_env',
};

const userPool = new CognitoUserPool(poolData);

const Header = (props) => {
  const [manualLogin, setManualLogin] = useRecoilState(manualLoginState);
  const [books_id_dwonload, setBooks_id_dwonload] = useRecoilState(
    books_id_dwonloadState
  ); // Update the path
  const [startPolling, setStartPolling] = useRecoilState(startPollingState); // Update the path
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [showLogoutOptions, setShowLogoutOptions] = useState(false);
  const secondPartInsideNameRef = useRef(null);
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [headerColumnsTranslation, setHeaderColumnsTranslation] =
    useRecoilState(headerColumnsTranslationState);
  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  const [loadingState, setLoadingState] = useRecoilState(loadingStateCompare);
  const [loadingmanuscript, setLoadingmanuscript] = useRecoilState(
    loadingmanuscriptState
  );
  const [genresData, setGenresData] = useRecoilState(genresDataState);
  const [keywords, setKeywords] = useRecoilState(keywordsState);
  const [genres, setGenres] = useRecoilState(genresState);
  const [notes, setNotes] = useRecoilState(notesAtom);
  const [content, setContent] = useRecoilState(contentAtom);
  const [chapterNames, setChapterNames] = useRecoilState(chapterNamesAtom);
  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [file, setFile] = useRecoilState(fileState);
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);
  const [idToken, setIdToken] = useRecoilState(idTokeneState);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [notes_highlights, setNotes_highlights] = useRecoilState(
    notes_highlightsState
  );
  const [numberOfUploadedFiles, setNumberOfUploadedFiles] = useRecoilState(
    numberOfUploadedFilesState
  );
  const [most_similar_book, setMost_similar_book] = useRecoilState(
    most_similar_bookAtom
  );
  const [initialHighlight, setInitialHighlight] = useRecoilState(
    initialHighlightState
  );
  const [userRejected_ApprovedNote, setUserRejected_ApprovedNote] =
    useRecoilState(userRejected_ApprovedNoteState);
  const [selectedChapter, setSelectedChapter] =
    useRecoilState(selectedChapterAtom);
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);

  //function to empty all the stares
  const emptyAllStates = () => {
    setUserInfo(null);
    setClickedManuscript([]);
    setCurrentManuscript([]);
    setHeaderColumnsTranslation([]);
    setPendingProcessing(0);
    setLoadingState(false);
    setLoadingmanuscript(false);
    setGenresData({});
    setKeywords([]);
    setGenres([]);
    setNotes([]);
    setManuscriptInfoList([]);
    setContent([]);
    setChapterNames([]);
    setScoreData({});
    setFile([]);
    setStartPolling(false);
    setSelectedChapter(0);
    setFileInfo([]);
    setIdToken(null);
    setAccessToken(null);
    setNotes_highlights([]);
    setNumberOfUploadedFiles(0);
    setMost_similar_book([]);
    setUserRejected_ApprovedNote([]);
    setBooks_id_dwonload([]);
    setCompanyInfo(null);
  };

  const navigate = useNavigate();

  const expendmanu = () => {
    getCompanyInformation();
    navigate('/user-profile');
  };

  const handleNavigation = () => {
    getManuscriptsInfo(false);
    navigate('/submissions');
  };

  const gotoSearch = () => {
    getManuscriptsInfo(false);
    navigate('/discover');
  };

  const handleRefresh = () => {
    getManuscriptsInfo(false);
    if (window.location.pathname != '/submissions') {
      navigate('/submissions');
    }
  };

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,

    // Add other functions from the hook if needed
  } = useApiUtils();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        secondPartInsideNameRef.current &&
        !secondPartInsideNameRef.current.contains(event.target)
      ) {
        // Check if the click target is not the "Logout" option
        if (!event.target.classList.contains(styles.logoutOptions)) {
          setShowLogoutOptions(false);
        }
      }
    };
    // Add the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogoutClick = () => {
    emptyAllStates();

    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }

    // Clear tokens and user-related data from sessionStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration');
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_expiration');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.clear();
    sessionStorage.clear();
    // emptyAllStates();

    //reset intercom session
    window.Intercom('shutdown');
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'l2515b7r',
      name: '',
      email: '',
    });

    navigate('/');
  };

  const toggleLogoutOptions = () => {
    setShowLogoutOptions(!showLogoutOptions);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  let name = '';
  let role = '';
  const firstLetter = getInitials(userInfo?.name ?? 'Unassigned');
  let colorCode;
  if (userInfo) {
    // firstLetter = userInfo.name.substring(0, 2).toUpperCase();
    name = userInfo.name;
    role = userInfo.role;
    //if role ===\v\1 so Editor else if role ===\v\2 so Reviewer else if role ===\v\3 so Manager
    if (role === 'lvl1') {
      role = 'Editor';
    } else if (role === 'lvl2') {
      role = 'Manager';
    }
  } else {
    // firstLetter = "*";
  }

  function getInitials(name) {
    if (!name) {
      return 'NA';
    }
    const words = name.split(' ');
    if (words.length === 1) {
      const firstLetter = words[0].charAt(0).toUpperCase();
      // const secondLetter = words[0].charAt(1).toLowerCase();
      // return `${firstLetter}${secondLetter}`;
      return `${firstLetter}`;
    } else {
      const firstWordInitial = words[0].charAt(0).toUpperCase();
      const secondWordInitial = words[1].charAt(0).toUpperCase();
      return `${firstWordInitial}${secondWordInitial}`;
    }
  }

  colorCode = 'rgba(28, 234, 160, 1)';
  const avatarStyle = {
    backgroundColor: colorCode,
    width: '25px',
    height: '25px',
    fontSize: '14px',
  };

  const selectStyle = {
    cursor: 'crosshair',
    border: 'none', // Remove the border
    outline: 'none', // Remove the focus outline
    width: '100%', // Set the width to fill the cell (excluding the avatar)
    marginLeft: 8, // Add margin to the left of the select box
    background: 'transparent', // Make the background transparent
    fontSize: 'inherit', // Inherit font size from the parent
    color: 'inherit', // Inherit color from the parent
    textAlign: 'left', // Align text to the left
    padding: 0, // Remove padding
  };

  function generateColorCode(name) {
    // Simple hash calculation based on the name
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash += name.charCodeAt(i);
    }

    // Generate a color code using the hash value
    const hue = hash % 360; // Use the hash value modulo 360 to determine the hue
    const saturation = 70; // Set a fixed saturation value
    const lightness = 60; // Set a fixed lightness value

    // Convert HSL values to a CSS color code
    const colorCode = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return colorCode;
  }
  return (
    <div className={manualLogin ? styles.manualHeader : styles.hedder}>
      <div className={styles.firstPart}>
        <img
          className={styles.hedderTitleImg}
          src={storyWiswLogo}
          onClick={handleRefresh}
        />
        <div className={styles.hedderTitle} onClick={handleRefresh}>
          Storywise
        </div>
        {props.issubmission && (
          <button
            className={
              manualLogin ? styles.manualmyManuscripts : styles.myManuscripts
            }
            onClick={handleRefresh}
          >
            <img className={styles.myM} src={book} />
            <div className={styles.totleManu}>My Manuscripts </div>
          </button>
        )}
        {!props.issubmission && (
          <button
            className={
              manualLogin ? styles.manualmyManuscripts : styles.tomyManuscripts
            }
            onClick={handleNavigation}
          >
            <img className={styles.myM} src={book} />
            <div className={styles.totleManu}>My Manuscripts </div>
          </button>
        )}

        {props.issettings && (
          <button
            className={`${styles.myManuscripts} ${styles.settingsButton}`}
            onClick={expendmanu}
          >
            <img className={styles.myM} src={deff} />
            <div className={styles.totleManu}>Settings </div>
          </button>
        )}
        {!props.issettings && (
          <button
            className={`${styles.tomyManuscripts} ${styles.settingsButton}`}
            onClick={expendmanu}
          >
            <img className={styles.myM} src={deff} />
            <div className={styles.totleManu}>Settings </div>
          </button>
        )}

        {props.issearch && (
          <button
            className={`${styles.myManuscripts} ${styles.settingsButton}`}
            onClick={gotoSearch}
          >
            <div className={styles.totleManu}>✨Discover</div>
          </button>
        )}
        {!props.issearch && (
          <button
            className={`${styles.tomyManuscripts} ${styles.settingsButton}`}
            onClick={gotoSearch}
          >
            <div className={styles.totleManu}>✨Discover</div>
          </button>
        )}
      </div>

      <div className={styles.secondPart}>
        <div
          className={styles.secondPartinsidename}
          ref={secondPartInsideNameRef}
        >
          <div
            className={styles.secondPartTexnamet1}
            onClick={toggleLogoutOptions}
          >
            <Avatar style={avatarStyle}>{firstLetter}</Avatar>
            {name}
            <img src={expenduser} className={styles.expenduser} />
          </div>
          {showLogoutOptions && (
            <div className={styles.logoutOptions} onClick={handleLogoutClick}>
              {/* <div className={styles.secondPartTexnamet} > */}
              <img src={logout} className={styles.logoutIcon} />
              Log Out
              {/* </div> */}
              {/* Add other logout options here */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
