import React from 'react';
import { Grid, Box, Typography, Avatar } from '@mui/material';
import { format } from 'date-fns';

const BookBlock = (props) => {
  return (
    <Grid
      container
      sx={{
        width: '200px',
        borderRadius: '8px',
        // border: '1px solid',
        borderColor: 'borderMain.main',
        ring: '1px solid',
        ringColor: 'borderMain.main',
        backgroundColor: '#f5f5f5',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
        p: '8px',
        cursor: 'pointer',
        marginBottom: '8px',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          maxWidth: '100%',
          paddingX: '8px',
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
      >
        {/* Title */}
        <Typography
          variant="body2"
          sx={{
            lineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'medium',
            color: 'textMain.main',
            textAlign: 'left',
            fontFamily: 'sans-serif',
          }}
        >
          {props.title}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Avatar
              src={props.image}
              //alt=""
              sx={{
                width: 16,
                height: 16,
                borderRadius: '0',
                border: '1px solid',
                borderColor: 'rgba(0, 0, 0, 0.1)',
              }}
            />
          </Box>

         
          <Typography
            variant="body2"
            sx={{
              marginX: '4px',
              fontWeight: 'medium',
              fontSize: '12px',
              color: 'textOff.main',
            }}
          >
            ·
          </Typography> */}

          {/* Time */}
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'medium',
              fontSize: '12px',
              color: 'textOff.main',
            }}
          >
            Uploaded: {format(new Date(props.time), 'MMM dd, yyyy')}
            <br />
            Status: {props.status}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BookBlock;
